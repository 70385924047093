<template>
  <div class="default-page default-page-center text-center">
    <div class="fs-1 text-center text-muted mb-4">
      <i class="bi bi-power"></i>
    </div>
    <p class="mb-4">Выйти из системы?</p>
    <button @click="logout" class="btn btn-lg btn-min-width rounded-pill btn-primary">Да, выйти</button>
  </div>
</template>

<script>
import { UserModel } from "@/models/UserModel";

export default {
  methods: {
    logout() {
      UserModel.current().logout();
      this.$router.push('/login');
    }
  }
}
</script>