import { delay } from "@/helpers/delay";
import loginPage from "@/components/LoginPage";

export const networkInformation = {
    namespaced: true,
    state() {
        return {
            _status: '',
            status: '', // loading, error, success, ''
            lastError: ''
        }
    },
    mutations: {
        async setNetworkInformation(state, {status, lastError = ''}) {
            state._status = status;
            if (status === 'loading') { // Устанавливаем state.status с задержкой чтобы loader в NetworkStatus (блок вверху) появляется не сразу
                await delay(500);
                if (['error', 'success'].includes(state._status)) {
                    return;
                }
                state.status = 'loading';
                return;
            }
            state.status = status;
            state.lastError = lastError;
        },
    }
}