<template>
  <div class="issues mb-4">
    <h3 class="mb-3"><span>Задачи:</span></h3>

    <div v-if="loading" class="text-center pb-3">
      <my-loader label="Загружаем задачи..." />
    </div>

    <div v-else-if="errorLoad" class="info-block text-center pb-3">
      <div class="info-block-icon text-danger" role="status">
        <i class="bi bi-slash-circle"></i>
      </div>
      <div class="info-block-label text-danger">Ошибка загрузки</div>
    </div>

    <div v-else-if="!tasks.length" class="info-block text-center pb-3">
      <div class="info-block-icon" role="status">
        <i class="bi bi-emoji-smile-fill"></i>
      </div>
      <div class="info-block-label">Пока что нет задач</div>
    </div>

    <div v-else class="pb-2">
      <form @submit.prevent="v.save()" v-for="v in tasks" class="issue d-block mb-3" :class="{'issue-done': v.is_done}">
        <div v-if="v.is_done" class="mb-2 d-flex">
          <div class="form-check-reopen-button">
            <button @click="v.reOpen()" class="btn btn-primary w-100 btn-sm mt-1 rounded-pill"><i class="bi bi-pencil-fill"></i></button>
          </div>
          <label class="form-check-label ms-3 mt-2">{{ v.task }}</label>
        </div>
        <div v-else class="form-check form-switch form-switch-lg mb-2">
          <input v-model="v.is_checked" type="checkbox" role="switch" :id="`flexSwitchCheckDefault_${v.id}`" class="form-check-input">
          <label :for="`flexSwitchCheckDefault_${v.id}`" class="form-check-label ms-3 mt-2" :class="{'issue-deadline-label-offset': +v.deadline_time}">{{ v.task }}</label>
          <div class="issue-deadline" v-if="+v.deadline_time">
            <span :class="getDeadlineColor(v.deadline_time)">До: {{ v.deadline_inFormat }}</span>
          </div>
        </div>
        <div v-if="!v.is_done" class="issue-desc text-secondary">
          {{ v.description }}
          <div v-if="v.need_show_additional_panel" class="mt-3 mb-4">
            <textarea class="form-control mb-3" rows="4" v-model="v.user_comment" placeholder="Комментарий к задаче..."></textarea>
            <img :src="v.photo_1 ? (v.photo_1.includes('data:image') ? v.photo_1 : `https://performance.kkosa.ru${v.photo_1}`) : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='" :id="`task-photo-${v.id}`" :style="`display: ` + (v.photo_1 ? 'block' : 'none')" class="issue-photo" />
            <div class="d-flex justify-content-between mb-3">
              <button type="button" @click="takePhoto(v)" class="btn flex-fill btn-secondary me-3">Сделать фото</button>
              <button type="button" @click="selectPhoto(v)" class="btn flex-fill btn-secondary me-3">Выбрать фото</button>
            </div>
            <div class="d-flex justify-content-between">
              <button type="submit" class="btn flex-fill btn-primary">Сохранить</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="text-center">
      <button class="btn btn-secondary btn-lg btn-min-width rounded-pill" @click="loadTasks">Обновить задачи</button>
    </div>
  </div>
</template>

<script>
import Api from "@/libraries/Api";
import { mapState, mapMutations } from "vuex";
import { getNow } from "@/helpers/format_date";
import { TaskModel } from "@/models/TaskModel";

export default {
  expose: [
      'loadTasks'
  ],
  data() {
    return {
      loading: false,
      errorLoad: false,
      now: getNow(),
    }
  },
  computed: {
    ...mapState({
      tasks: state => state.tasks.tasks,
      shiftParams: state => state.tasks.shiftParams,
    })
  },
  methods: {
    ...mapMutations({
      setTasks: 'tasks/setTasks',
      setShiftParams: 'tasks/setShiftParams',
    }),
    async loadTasks() {
      if (this.loading) { // Stop double run
        return;
      }

      const loadShiftParams = async () => {
          const r = await Api.get('shift_params');
          console.log('shiftParams:', r);
          // this.shiftParams = r;
          this.setShiftParams(r);
      }

      const fillLocalDB = async () => { // Заполняем локальную БД
        const tasks = await Api.get('tasks');
        localStorage.setItem(
            `DB_tasks`,
            JSON.stringify( tasks )
        );
      }

      try {
        this.loading = true;
        this.errorLoad = false;

        await fillLocalDB();
        this.setTasks( new TaskModel().getList() ); // Сохраняем список задач из локальной БД в переменную tasks

        await loadShiftParams();

      } catch (e) {
        this.errorLoad = true;
        console.error('Error load tasks:', e);

      } finally {
        this.loading = false;
      }
    },
    takePhoto(taskModel) {
      const pictureOptions = {
        cameraDirection: Camera.Direction.BACK,
        saveToPhotoAlbum: true,
        destinationType: Camera.DestinationType.DATA_URL,
        quality: 80,
      };
      navigator.camera.getPicture(
          image => {
            const img = document.getElementById(`task-photo-${taskModel.id}`);
            img.src = taskModel.photo_1 = `data:image/jpeg;base64,${image}`;
            img.style.display = 'block';
          },
          () => {},
          pictureOptions
      );
    },
    selectPhoto(taskModel) {
      const pictureOptions = {
        cameraDirection: Camera.Direction.BACK,
        saveToPhotoAlbum: true,
        destinationType: Camera.DestinationType.DATA_URL,
        quality: 80,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY
      };
      navigator.camera.getPicture(
          image => {
            const img = document.getElementById(`task-photo-${taskModel.id}`);
            img.src = taskModel.photo_1 = `data:image/jpeg;base64,${image}`;
            img.style.display = 'block';
          },
          () => {},
          pictureOptions
      );
    },
    getDeadlineColor(deadline) {
      const d = new Date();
      const time = 3600*d.getHours() + 60*d.getMinutes() + d.getSeconds();
      let color = '';
      if (deadline - time <= 3600) {
        color = 'text-bg-warning';
      }
      if (deadline - time <= 0) {
        color = 'text-bg-danger';
      }
      return color;
    }
  },
  mounted() {
    if (this.tasks.length) {
      return;
    }
    this.loadTasks();
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/scss/bootstrap-declaration.scss";
@import "@/styles/scss/bootstrap-overwrite.scss";

.issues {
  .issue {
    position: relative;

    .form-check {
      display: flex;
      flex-direction: row;

      &-input,
      &-reopen-button{
        min-width: 60px;
      }

      &-label {
      }
    }

    &-deadline {
      font-size: 13px;
      white-space: nowrap;
      position: absolute;
      right: 0;
      top: 13px;

      > span {
        background: #222529;
        border: none;
        padding: 2.5px 7.5px;
        color: #FFF !important;
        border-radius: 10px;
        text-shadow: none;
      }
    }

    &-deadline-label-offset {
      margin-right: 70px;
    }

    &-desc {
      line-height: 1.25;
      white-space: pre-line;
    }

    &-done {
      color: rgba(var(--bs-secondary-rgb), 1);

      .issue-desc {
        display: none;
      }
    }

    &-photo {
      display: block;
      border-radius: 6px;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.info-block {
  line-height: 1;

  &-icon {
    font-size: 31px;
  }

  &-label {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
</style>