<template>
  <div class="fixed-bottom bottom-main-menu">
    <button class="bottom-main-menu-item" @click="$emit('openFullMenu')">
      <i class="bi bi-list"></i>
      <span>Всё меню</span>
    </button>
    <router-link v-for="item in menu" class="bottom-main-menu-item" :class="{'bottom-main-menu-item-active': currentPage === item.link}" :to="item.link">
      <i class="bi" :class="item.icon"></i>
      <span>
        {{ item.name }}
        <span v-if="item.indicator" :class="item.indicatorCssClasses">{{ item.indicator }}</span>
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatTimer } from "@/helpers/format_date";

export default {
  computed: {
    ...mapState({
      IS_USER_AUTH: state => state.IS_USER_AUTH,
      workTime: state => state.timer.workTime,
      isWorking: state => state.timer.isWorking
    }),
    currentPage() {
      return this.$route.path;
    },
    menu() {
      const menuAuth = [
        {
          link: '/',
          name: 'Смена',
          icon: 'bi-clipboard-check-fill',
          indicator: formatTimer(this.workTime),
          indicatorCssClasses: {
            'badge rounded-pill': true,
            'text-bg-success': !this.isWorking,
            'text-bg-danger': this.isWorking,
          }
        },
        // {
        //   link: '/chats',
        //   name: 'Чаты',
        //   icon: 'bi-chat-dots-fill'
        // },
        // {
        //   link: '/rest',
        //   name: 'Для отдыха',
        //   icon: 'bi-controller'
        // }
      ];

      const menuNotAuth = [
        {
          link: '/login',
          name: 'Авторизация',
          icon: 'bi-lock-fill'
        }
      ];

      return this.IS_USER_AUTH ? menuAuth : menuNotAuth;
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottom-main-menu {
    background: #111;
    border-top: 1px solid #151515;
    height: 59px;
    padding: 0 15px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.99);

    &-item {
      background: transparent;
      border: none;
      color: rgba(255, 255, 255, 0.5);
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: color 0.25s linear;
      text-decoration: none;
      font-size: 21px;
      line-height: 1;
      //font-size: 30px;

      &-active, &:hover, &:active, &:focus {
        color: rgba(255, 255, 255, 1);
      }

      span {
        line-height: 1;
        font-size: 13px;
        white-space: nowrap;
        padding-top: 5px;
        //display: none;

        .badge {
          font-size: 11px;
          padding: 2px 6px;
          color: #FFF !important;
        }
      }
    }
  }
</style>