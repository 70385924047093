export class Model {
    constructor(table, id = null) {
        this.data = {};
        this.table = table;
        if (id) {
            const data = this.getDataFromDB(id);
            this.setData(data || {});
        }
    }
    getData() {
        return this.data;
    }
    setData(data) {
        this.data = data;
    }
    __get(target, prop, receiver) {
        if (target[prop] !== undefined) {
            return target[prop];
        }
        else if (target.data[prop] !== undefined) {
            return target.data[prop];
        }
        return undefined;
    }
    __set(target, prop, value, receiver) {
        if (target[prop] !== undefined) {
            target[prop] = value;
        }
        else if (target.data[prop] !== undefined) {
            target.data[prop] = value;
        }
        else {
            target[prop] = value;
        }
        return true;
    }
}
