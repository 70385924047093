import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directives from '@/directives'
import components from '@/components/UI'

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'

const app = createApp(App)

directives.forEach(v => {
    app.directive(v.name, v)
})
components.forEach(v => {
    app.component(v.name, v)
})

app
    .use(store)
    .use(router)
    .mount('#app')