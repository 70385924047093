import axios from 'axios';
import { IsApiResponse, ToApiResponse } from '@/helpers/api';
import { UserModel } from "@/models/UserModel";
import EnvConfig from "@/env";
import store from "@/store";

export default {
    API_ROOT_URL: `${EnvConfig.SITE_ROOT_URL}api/v1.0/`,
    CONF_TIMEOUT: 5000,
    lastResponse: {},

    _prepareResponse(response) {
        // If response in incorrect format, then show error
        if (!IsApiResponse(response)) {
            response = ToApiResponse(response);
            response.is_error = true;
        }
        // If both flags (is_success and is_error) set as false, then show error
        if (!response.is_success && !response.is_error) {
            response.is_error = true;
        }
        return response;
    },

    async _call(uri, method, data) {
        store.commit('networkInformation/setNetworkInformation', { status: 'loading' });

        const send_request_time = Math.floor(Date.now() / 1000);
        try {
            const g_user = UserModel.current();
            const serverResponse = (await axios({
                url: `${this.API_ROOT_URL}${uri}`,
                responseType: 'json',
                timeout: 5000,
                method,
                params: method === 'GET' ? data : [],
                data,
                headers: {
                    'Content-Type' : 'application/json; charset=UTF-8',
                    'API-Request-Time': send_request_time.toString(),
                    'API-User-Auth-Login': g_user.login,
                    'API-User-Auth-Token': g_user.token
                },
            }));
            this.lastResponse = {
                ...this._prepareResponse(serverResponse.data),
                is_response_from_server: IsApiResponse(serverResponse.data),
                status: 200,
                send_request_time,
                get_response_time: Math.floor(Date.now() / 1000),
            };

            if (this.lastResponse.is_success) {
                store.commit('networkInformation/setNetworkInformation', { status: 'success', lastError: 'none' });
                return this.lastResponse.data;
            }
        } catch (e) {
            // Сервер ответил не 200-ым статусом
            const serverResponse = e?.response?.data || null;
            this.lastResponse = {
                ...this._prepareResponse(serverResponse),
                is_response_from_server: IsApiResponse(serverResponse),
                status: e?.response?.status || 0,
                send_request_time,
                is_server_maintenance: e?.response?.status === 503,
                error: e
            };
            store.commit(
                'networkInformation/setNetworkInformation',
                {
                    status: this.lastResponse.is_response_from_server ? 'success' : 'error',
                    lastError: this.lastResponse?.error?.message
                }
            );

            throw this.lastResponse;
        }

        // Сервер вернул статус 200, но при этом is_error=true
        store.commit(
            'networkInformation/setNetworkInformation',
            {
                status: IsApiResponse(this.lastResponse) ? 'success' : 'error',
                lastError: this.lastResponse?.error?.message
            }
        );
        throw {
            ...this.lastResponse,
            is_response_from_server: IsApiResponse(this.lastResponse),
            status: 200,
            send_request_time,
            error: new Error("The server said that it is error, but page status is 200")
        }
    },

    isIncorrectServerResponse() {
        const response = this.lastResponse;
        return (
            !response.is_response_from_server // Если формат ответа неверный (сервер например ответил ошибкой или отвалился по timeout-у, в общем любой вариант когда он ответил не в согласованном формате, а как-то иначе)
            ||
            (response?.is_server_maintenance ?? false) // Если сервер на тех. обслуживании
        );
    },

    isCorrectServerResponse() {
        return !this.isIncorrectServerResponse();
    },

    async get(uri, data = []) {
        return this._call(uri, 'GET', data);
    },

    // Создание ресурса
    async post(uri, data) {
        return this._call(uri, 'POST', data);
    },

    // Замена ресурса целиком
    async put(uri, data) {
        return this._call(uri, 'PUT', data);
    },

    // Редактирование ресурса
    async patch(uri, data) {
        return this._call(uri, 'PATCH', data);
    },

    async delete(uri, data = []) {
        return this._call(uri, 'DELETE', data);
    }
}