export const tasksModule = {
    namespaced: true,
    state() {
        return {
            tasks: [],
            SYNC_TASKS_TIME: 500,
            shiftParams: []
        }
    },
    mutations: {
        setTasks(state, tasks) {
            state.tasks = tasks;
        },
        setShiftParams(state, shiftParams) {
            state.shiftParams = shiftParams;
        }
    }
}