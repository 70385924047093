<template>
  <div id="game1-page">
    <div id="container">
      <div id="game"></div>
      <div id="score">0</div>
      <div id="instructions">Нажмите на счёт что бы поставить на паузу</div>
      <div class="game-over">
        <h2>Конец игры</h2>
        <p>Нажмите, чтобы начать...</p>
      </div>
      <div class="game-ready">
        <div id="start-button">Старт</div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Game } from '@/libraries/game-n1';

export default {
  mounted() {
    new Game();
  }
}
</script>

<style lang="scss" scoped>
#game1-page {
  $color-dark: #333344;
  text-shadow: none;

  #container {
    width: 100%;
    height: 100%;

    #score {
      position: absolute;
      top: 20px;
      width: 100%;
      text-align: center;
      font-size: 10vh;
      transition: transform 0.5s ease;
      color: $color-dark;
      transform: translatey(-200px) scale(1);
    }

    #game {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .game-over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      * {
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 0;
        transform: translatey(-50px);
        color: $color-dark;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: 40px;
      }
    }

    .game-ready {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      #start-button {
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 0;
        transform: translatey(-50px);

        border: 3px solid $color-dark;
        padding: 10px 20px;
        background-color: transparent;
        color: $color-dark;
        font-size: 30px;
      }
    }

    #instructions {
      position: absolute;
      width: 100%;
      top: 16vh;
      left: 0;
      text-align: center;
      transition: opacity 0.5s ease, transform 0.5s ease;
      color: #000;
      opacity: 0;

      &.hide {
        opacity: 0 !important;
      }
    }

    &.playing, &.resetting {
      #score {
        transform: translatey(0px) scale(1);
      }
    }

    &.playing {
      #instructions {
        opacity: 1;
      }
    }

    &.ready {


      .game-ready {
        #start-button {
          opacity: 1;
          transform: translatey(0);
        }
      }
    }

    &.ended {
      #score {
        transform: translatey(6vh) scale(1);
      }

      .game-over {
        * {
          opacity: 1;
          transform: translatey(0);
        }

        p {
          transition-delay: 0.3s;
        }
      }
    }
  }
}
</style>