<template>
  <div class="default-page default-page-center">
    <form @submit.prevent="tryLogin" class="login-form">
      <div class="fs-1 text-center text-muted mb-4">
        <i class="bi bi-lock-fill"></i>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errs.length" v-html="errs.join('<br>')"></div>
      <div class="form-floating input-with-bottom-line-only mb-4">
        <input type="text" class="form-control" id="floatingLogin" placeholder="Логин" v-model="login">
        <label for="floatingEmail">Логин</label>
      </div>
      <div class="form-floating input-with-bottom-line-only mb-4">
        <input type="password" class="form-control" id="floatingPwd" placeholder="Логин" v-model="password">
        <label for="floatingPwd">Пароль</label>
      </div>
      <div class="text-center pt-3">
        <button type="submit" class="btn btn-primary btn-lg btn-min-width rounded-pill">
          Войти
          <i class="ms-2 bi bi-arrow-right-circle-fill"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { UserModel } from "@/models/UserModel";

export default {
  data() {
    return {
      errs: [],
      login: '',
      password : ''
    }
  },
  methods: {
    async tryLogin() {
      const isLoggedIn = await UserModel.current().tryLogin(this.login, this.password);

      if (isLoggedIn) {
        this.$router.push('/');
        return;
      }
      this.errs = ['Ошибка авторизации'];
    },
  },
  async mounted() {
    if (!this.$store.state.IS_USER_AUTH) {
      return;
    }
    this.$router.push('/');
  }
}
</script>

<style lang="scss" scoped>
  .login-form {
    max-width: 400px;
    margin: auto;
  }
</style>