import { createStore } from 'vuex'
import Api from "@/libraries/Api";
import { UserModel } from "@/models/UserModel";
import { timerModule } from "@/store/timerModule";
import { tasksModule } from "@/store/tasksModule";
import { networkInformation } from "@/store/networkInformation";

export default createStore({
  modules: {
    timer: timerModule,
    tasks: tasksModule,
    networkInformation,
  },
  state() {
    return {
      IS_USER_AUTH: false,

      actions: [],
      SYNC_ACTIONS_TIME: 2500,
    }
  },
  mutations: {
    setUserAuth(state, value) {
      state.IS_USER_AUTH = value;
    },

    addAction(state, action) {
      state.actions.push({
        action,
        synchronized: false
      });
    },
  },
  actions: {
    async initSyncActions({state}) {
      let synchronizing = false;

      const sync = async () => {
        if (synchronizing) {
          return;
        }
        synchronizing = true;

        for (const action of state.actions) {
          try {
            await Api[action.action.method](action.action.url, action.action.params);
            action.synchronized = true;
          } catch (e) {
            action.error = Api.lastResponse?.data;
          }
        }

        state.actions = state.actions.filter(v => !v.synchronized);
        synchronizing = false;
      }

      setInterval(
        async () => await sync(),
        state.SYNC_ACTIONS_TIME
      );
      await sync();
    },
    initWorkTimeSync({dispatch, commit, state}) {
      const sync = async () => {
        // Если таймер выключен, то синхронизуруем время с тем, что на сервере (таймер может быть включён либо на сайте, либо в приложении)
        if (!state.timer.isWorking) {
          const getTimerValue = async () => {
            return await Api.get(`current_timer_value`);
          }
          const timerValue = await getTimerValue() || 0;
          console.log('getTimerValue()=', timerValue);
          commit('timer/setTimer', timerValue);
          return;
        }

        // If timer value not change, then skip sync
        if (this.lastSetValue === state.timer.workTime || !state.timer.workTime) {
          return;
        }

        // Remove old timer
        const user_id = UserModel.current().id;
        const timerUri = `users/${user_id}/timer`;
        state.actions = state.actions.filter(v => v.action.url !== timerUri);

        // Add new timer
        state.actions.push({
          action: {
            method: 'patch',
            url: timerUri,
            params: {
              value: state.timer.workTime,
              workIntervals: state.timer.workIntervals
            }
          },
          error: ''
        });
        this.lastSetValue = state.timer.workTime;
      }

      setInterval(
          sync,
          state.timer.SYNC_WORK_TIME_TIME
      );
      sync();
    },

    initTaskSync({state}) {
      const sync = () => {
        state.tasks.tasks.forEach(v => {
          if (!v.needSync) {
            return;
          }
          state.actions.push({
            action: {
              method: 'patch',
              url: `tasks/${v.id}`,
              params: {'is_set': true, ...v.getData()}
            },
            error: ''
          });
          v.needSync = false;
        });
      }

      setInterval(
          sync,
          state.tasks.SYNC_TASKS_TIME
      );
      sync();
    },

    async init({dispatch, state}) {
      state.IS_USER_AUTH = UserModel.current().isAuth();

      await dispatch('initSyncActions', {state}); // Every 2.5s run queue of actions (others calls to api). For example: sync tasks statuses, comments, work timer

      dispatch('timer/initWorkTimeCalculation', {state}); // Every second update visible timer
      dispatch('initWorkTimeSync', {state}); // Every 5s sync add action "sync current timer with server"

      dispatch('initTaskSync', {state}); // Every 0.5s sync add action "sync current state of tasks with sever (for example: task closed => need sync, add comment into task => need sync, and ect.)
    }
  }
});