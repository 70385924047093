<template>
  <div id="dashboard-page" class="default-page">
    <div class="modal fade" id="closeShiftModal" tabindex="-1" aria-labelledby="closeShiftModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title text-secondary fs-5" id="closeShiftModalLabel">Подтверждение</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            Вы уверены что хотите закрыть смену?<br>
            <div v-if="countActiveTasks">
              У вас не выполнено {{ countActiveTasks }} из {{ tasks.length }} задач
            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" @click="gotoStep2" class="btn btn-lg btn-primary btn-min-width rounded-pill" data-bs-dismiss="modal">Да, закрыть</button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="step === 1">
      <div class="text-center mb-4">
        <h3 class="text-start mb-3"><span>Смена:</span></h3>
        <div class="work-timer mb-3">
          <div class="text-secondary">Отработано времени</div>
          <div class="work-timer-value">{{ workTimeInFormat }}</div>
        </div>
        <div class="mb-2">
          <button class="btn btn-lg btn-min-width rounded-pill" :class="{'btn-primary': !isWorking, 'btn-secondary': isWorking}" @click="toggleWorkTimer">
            {{ btnWorkingText }}
          </button>
        </div>
        <div>
          <button :class="{'invisible': !isWorking}" class="btn btn-lg btn-danger btn-min-width rounded-pill" data-bs-toggle="modal" data-bs-target="#closeShiftModal">
            Закрыть смену
          </button>
        </div>
      </div>
      <current-issues-page ref="issuesListComponent" />
    </div>

    <div v-show="step === 2">
      <form class="mb-4" @submit.prevent="sendShiftData">
        <h3 class="text-start mb-3"><span>Завершение смены:</span></h3>

        <div class="work-timer mb-3">
          <div class="text-secondary">Отработано времени</div>
          <div class="work-timer-value">{{ workTimeInFormat }}</div>
        </div>
        <div class="issues mb-4">
          <div class="text-secondary mb-1">Задачи</div>
          <div v-for="task in tasks" :key="task.id" class="issues-issue">
            <span class="pe-3">
              <i v-if="task.is_done" class="issues-issue-icon bi bi-check-circle text-success"><span></span></i>
              <i v-else class="issues-issue-icon bi bi-x-circle text-danger"><span></span></i>
            </span>
            <span>{{ task.task }}</span>
          </div>
        </div>
        <div class="shift-params mb-3">
          <div class="text-secondary mb-1">Завершающие параметры</div>
          <div v-for="param in shiftParams" :key="+param.id">
            <div v-if="+param.param.type === ParamModel.TYPE_NUMBER">
              <div class="mb-2">
                <input type="number" class="form-control" :placeholder="param.param.name" @input="setFinalParam(+param.id, $event.target.value)">
              </div>
            </div>
            <div v-else-if="+param.param.type === ParamModel.TYPE_STRING">
              <div class="mb-2">
                <input type="text" class="form-control" :placeholder="param.param.name" @input="setFinalParam(+param.id, $event.target.value)">
              </div>
            </div>
            <div v-else-if="+param.param.type === ParamModel.TYPE_IMAGE">
              <div class="mb-2">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" id="finalPhoto" class="photo-preview" />
                <button type="button" @click="makeWorkspacePhoto" class="btn btn-secondary w-100"><i class="bi bi-camera-fill me-3"></i>{{ param.param.name }}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="sendError" class="alert alert-danger text-center mb-3">Ошибка отправки. Попробуйте пожалуйста ещё раз</div>
        <div class="text-center">
          <button type="button" @click="gotoStep1" class="btn btn-link to-previous-step-button"><i class="bi bi-arrow-left-circle pe-2"></i></button>
          <button type="submit" class="btn btn-primary btn-lg btn-min-width rounded-pill">Отправить отчёт</button>
        </div>
      </form>
    </div>

    <div v-show="step === 3" class="step-panel">
      <div>
        <div class="spinner-border text-center" role="status">
          <span class="visually-hidden">Загрузка...</span>
        </div>
        <div class="text-center">Отправляем данные</div>
      </div>
    </div>

    <div v-show="step === 4" class="step-panel">
      <div class="text-center">
        <i class="bi bi-check-circle-fill text-success" style="font-size: 55px;"></i>
      </div>
      <div class="text-center mb-3">Отчёт отправлен</div>
      <div class="text-center">
        <button class="btn btn-lg btn-secondary rounded-pill btn-min-width" @click="step = 1">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { formatTimer } from "@/helpers/format_date";
import { ParamModel } from "@/models/ParamModel";
import CurrentIssuesPage from "@/components/CurrentIssuesPage";
import Api from "@/libraries/Api";

export default {
  name: 'DashboardPage',
  components: {
    CurrentIssuesPage
  },
  data() {
    return {
      ParamModel: Object.freeze(ParamModel),
      step: 1,
      finalPhoto: null,
      finalParams: {
        'finalPhoto': '',
      },
      sendError: false,
    }
  },
  computed: {
    ...mapState({
      workTime: state => state.timer.workTime,
      workTimeInFormat: state => formatTimer(state.timer.workTime),
      isWorking: state => state.timer.isWorking,
      tasks: state => state.tasks.tasks,
      shiftParams: state => state.tasks.shiftParams,
    }),
    countActiveTasks() {
      return this.tasks.filter(v => !v.is_done).length;
    },
    btnWorkingText() {
      return this.isWorking
        ? 'Перерыв'
        : (this.workTime ? 'Продолжить' : 'Начать смену');
    }
  },
  methods: {
    ...mapMutations({
      startWorking: 'timer/startWorking',
      stopWorking: 'timer/stopWorking',
      clearTimer: 'timer/clear',
    }),
    toggleWorkTimer() {
      this.isWorking ? this.stopWorking() : this.startWorking();
    },
    gotoStep1() {
      this.step = 1;
    },
    gotoStep2() {
      this.stopWorking();
      this.step = 2;
    },
    setFinalParam(param_id, value) {
      this.finalParams['param_' + param_id] = value;
    },
    makeWorkspacePhoto() {
      const pictureOptions = {
        cameraDirection: Camera.Direction.FRONT,
        saveToPhotoAlbum: false,
        destinationType: Camera.DestinationType.DATA_URL,
        quality: 80,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY
      };
      navigator.camera.getPicture(
          image => {
            const img = document.getElementById(`finalPhoto`);
            img.src = this.finalPhoto = `data:image/jpeg;base64,${image}`;
            img.style.display = 'block';

            this.finalParams['finalPhoto'] = this.finalPhoto
          },
          pictureOptions
      );
    },
    async sendShiftData() {
      this.sendError = false;
      this.step = 3;

      try {
        const r = await Api.post('shift', {
          'final_params': this.shiftParams
        });
        this.step = 4;
        this.clearTimer();
        this.$refs.issuesListComponent.loadTasks();
      } catch (e) {
        console.log('error:', e);
        this.step = 2;
        this.sendError = true;
      }
    }
  },
  activated() {
  }
}
</script>

<style lang="scss" scoped>
#dashboard-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .work-timer {
    &-value {
      font-size: 50px;
      line-height: 1.25;
    }
  }

  .to-previous-step-button {
    font-size: 25px;
  }

  .step-panel {
    height: calc(100vh - 59px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .issues {
    &-issue {
      display: flex;
      align-items: center;

      &-icon {
        font-size: 25px;
      }
    }
  }

  .shift-params {
    .photo-preview {
      display: none;
      border-radius: 6px;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
</style>