<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasUserMenu" aria-labelledby="offcanvasUserMenuLabel" v-swipe-left="closeMenu">
    <div class="offcanvas-header mt-1 pt-0 pb-0">
      <button type="button" class="btn btn-link btn-close-menu text-white" ref="menuCloseButton" data-bs-dismiss="offcanvas" aria-label="Закрыть">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="offcanvas-menu-list">
        <ul class="list-unstyled" data-bs-dismiss="offcanvas">
          <li v-for="item in menuTop">
            <router-link class="menu-item" :class="{active: currentPage === item.link}" :to="item.link"><span>{{ item.name }}</span></router-link>
          </li>
        </ul>
      </div>
      <div class="offcanvas-menu-list-bottom">
        <div class="header text-secondary">Пока вы ждёте:</div>
        <ul class="list-unstyled" data-bs-dismiss="offcanvas">
          <li v-for="item in menuBottom">
            <router-link class="menu-item" :class="{active: currentPage === item.link}" :to="item.link"><span>{{ item.name }}</span></router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-dark bg-black">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" ref="menuOpenButton" data-bs-toggle="offcanvas" href="#offcanvasUserMenu" role="button" aria-controls="offcanvasUserMenu">
        <span class="bi bi-list"></span>
      </button>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      IS_USER_AUTH: state => state.IS_USER_AUTH,
    }),
    currentPage() {
      return this.$route.path;
    },
    menuTop() {
      const menuTopAuth = [
        {
          link: '/',
          name: 'Текущая смена',
          keepAlive: true,
          componentName: 'DashboardPage'
        },
        // {
        //   link: '/chats',
        //   name: 'Чаты',
        // },
        // {
        //   link: '/archive',
        //   name: 'Архив смен',
        //   keepAlive: true,
        //   componentName: 'ArchivePage'
        // },
        // {
        //   link: '/stats',
        //   name: 'Статистика',
        // },
        {
          link: '/settings',
          name: 'Настройки',
        },
        {
          link: '/logout',
          name: 'Выход',
        }
      ];

      const menuTopNotAuth = [
        {
          link: '/login',
          name: 'Авторизация',
        }
      ];

      return this.IS_USER_AUTH ? menuTopAuth : menuTopNotAuth;
    },
    menuBottom() {
      return [
        // {
        //   link: '/rest',
        //   name: 'Для отдыха',
        //   keepAlive: true,
        //   componentName: 'RestPage'
        // },
        // {
        //   link: '/jokes',
        //   name: 'Анекдоты',
        //   keepAlive: true,
        //   componentName: 'JokesPage'
        // },
        {
          link: '/contacts',
          name: 'Написать разработчикам',
        },
        {
          link: '/check-updates',
          name: 'Проверить обновления',
        }
      ];
    }
  },
  methods: {
    openMenu() {
      this.$refs.menuOpenButton.click();
    },
    closeMenu() {
      this.$refs.menuCloseButton.click();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/scss/bootstrap-declaration.scss";
@import "@/styles/scss/bootstrap-overwrite.scss";

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 50%;
  display: none;

  .navbar-toggler {
    box-shadow: none !important;
    border: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 36px;
    color: #FFF;
  }
}

.offcanvas {
  background: #111;

  .btn-close-menu {
    font-size: 28px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0;
  }

  .menu-item {
    color: #FFF;
    padding: 10px 0;
    margin: 10px 0;
    white-space: nowrap;
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;

    &.active {
      color: $primary;
    }
  }

  .header {
    border-bottom: 1px solid;
    margin-bottom: 15px;
    white-space: nowrap;
    display: inline-block;
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>