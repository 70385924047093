import { getNow } from "@/helpers/format_date";

export const timerModule = {
    namespaced: true,
    state() {
        return {
            workTime: 0, // Вынесено сюда для реактивного отображения в bottom-menu (для отработанного времени)
            isWorking: false, // Вынесено сюда для реактивного отображения в bottom-menu (для цвета)
            workIntervals: [],
            SYNC_WORK_TIME_TIME: 5000,
        }
    },
    mutations: {
        startWorking(state) {
            state.isWorking = true;
            state.workIntervals.push({ 'timestamp': getNow(), 'type': 'start' });
        },

        stopWorking(state) {
            state.isWorking = false;
            state.workIntervals.push({ 'timestamp': getNow(), 'type': 'stop' });
        },

        clear(state) {
            state.workIntervals.length = 0;
            state.workTime = 0;
        },

        setTimer(state, value) {
            console.log('setTimerValue in store = ', value);
            state.workTime = value;
        }
    },
    actions: {
        _calcWorkTime({state}) {
            let summaryWorkTime = 0;
            for (let i = 0; i < state.workIntervals.length; i+=2) {
                let start = state.workIntervals[i]?.timestamp;
                let stop  = state.workIntervals[i+1]?.timestamp || getNow();
                summaryWorkTime += stop - start;
            }
            state.workTime = summaryWorkTime;
        },

        initWorkTimeCalculation({dispatch, state}) {
            const updateWorkTime = () => {
                if (!state.isWorking) {
                    return;
                }
                dispatch('_calcWorkTime')
            }
            setInterval(updateWorkTime, 1000);
            updateWorkTime();
        },
    }
}