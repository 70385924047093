<template>
  <div class="default-page default-page-center text-center">
    <h3>ООО Абис Плюс</h3>
    <br>
    <p>
      г. Калининград,
      пл. Василевского 2,
      5 этаж,
      офис 22
    </p>
    <br>
    <a href="mailto:support@abis39.ru" class="btn btn-primary rounded-pill btn-lg btn-min-width">support@abis39.ru</a>
    <br><br>
    <a href="tel:+74012374717" class="btn btn-primary rounded-pill btn-lg btn-min-width">8 (4012) 37-47-17</a>
  </div>
</template>