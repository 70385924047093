<template>
  <div class="network-status" v-if="isShow" :class="className">
    <div class="spinner-border spinner-border-sm network-status-element network-status-element-on-loading" role="status"><span class="visually-hidden">Загрузка...</span></div>
    <i class="bi bi-wifi-off network-status-element network-status-element-on-error" @click="showInformation = !showInformation"></i>
    <div class="network-status-information network-status-element network-status-element-on-error" v-show="showInformation" v-text="information"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showInformation: false
    }
  },
  computed: {
    ...mapState({
      isShow: state => state.networkInformation.status !== 'success',
      status: state => state.networkInformation.status,
      information: state => state.networkInformation.lastError
    }),
    className() {
      return `network-status-` + this.status
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/scss/bootstrap-declaration.scss";
@import "@/styles/scss/bootstrap-overwrite.scss";

.network-status {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-width: 28px;
  min-height: 28px;
  width: fit-content;
  margin: 0 auto;
  line-height: 1;
  padding: 5px;
  border-radius: 0 0 5px 5px;

  &-element {
    display: none;
  }

  &-loading {
    background: #444;

    .network-status-element-on-loading {
      display: block;
      text-align: center;
      margin: 1px auto 0 auto;
      border-width: 2px;
    }
  }

  &-error {
    background: $danger;

    .network-status-element-on-error {
      display: block;
    }
  }

  &-information {
    font-size: 13px;
  }
}
</style>