import { addMagicMethods } from "@/helpers/addMagicMethods";
import { ModelLocalStorageDB } from "@/models/ModelLocalStorageDB";
import { UserModel } from "@/models/UserModel";
import { formatTimer } from "@/helpers/format_date";
export class TaskModel extends ModelLocalStorageDB {
    constructor(id = null) {
        super('tasks', +id);
        this.is_checked = false;
        const ret = addMagicMethods(this);
        ret.is_checked = +id ? ret.is_done : false;
        return ret;
    }
    __get(target, prop, receiver) {
        let ret;
        switch (prop) {
            case 'user':
                ret = new UserModel(+receiver.user_id);
                break;
            case 'deadline_inFormat':
                ret = formatTimer(receiver.deadline_time, false);
                break;
            case 'is_done':
                ret = +receiver.status === TaskModel.STATUS_DONE;
                break;
            case 'need_show_additional_panel':
                ret = receiver.is_checked && !receiver.is_done;
                break;
            default:
                ret = super.__get(target, prop, receiver);
        }
        return ret;
    }
    __set(target, prop, value, receiver) {
        super.__set(target, prop, value, receiver);
        return true;
    }
    getList() {
        const all = this.getTableData();
        return all.map(v => new TaskModel(v.id));
    }
    save() {
        this.status = TaskModel.STATUS_DONE;
        this.needSync = true;
        this.flush();
    }
    reOpen() {
        this.status = TaskModel.STATUS_CREATED;
    }
}
TaskModel.STATUS_CREATED = 0;
TaskModel.STATUS_DONE = 2;
