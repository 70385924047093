<template>
  <div class="default-page">
    <h1>Ваш профиль</h1>
    <div>
      <div class="text-center">
        <div style="background: #333 url('https://avatars.githubusercontent.com/u/4570598?v=4') center center no-repeat; background-size: 100% auto; width: 150px; height: 150px; border-radius: 50%; margin: 33px auto 15px auto;"></div>
        <p class="text-center">{{ user.full_name }}</p>
      </div>
      <div class="mb-3">
        <label class="form-label">Имя</label>
        <input type="text" class="form-control" :value="user.first_name">
      </div>
      <div class="mb-3">
        <label class="form-label">Фамилия</label>
        <input type="text" class="form-control" :value="user.last_name">
      </div>
      <div class="mb-3">
        <label class="form-label">Должность</label>
        <input type="text" class="form-control" value="Контролёр КПП">
      </div>
      <div class="mb-3">
        <label class="form-label">Логин</label>
        <input type="text" class="form-control" :value="user.login">
      </div>
      <div class="mb-3">
        <label class="form-label">Номер телефона</label>
        <input type="text" class="form-control" :value="user.phone">
      </div>
      <div class="mb-3">
        <label class="form-label">Пароль</label>
        <div>
          <button class="btn btn-secondary btn-min-width rounded-pill"><i class="bi bi-lock-fill me-3"></i>Сменить</button>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Телеграм</label>
        <div>
          <button class="btn btn-secondary btn-min-width rounded-pill"><i class="bi bi-telegram me-3"></i>Привязать</button>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Хотите рассказать о себе?</label>
        <textarea class="form-control"rows="3"></textarea>
      </div>
      <div class="text-center mt-4 mb-4">
        <button type="button" class="btn btn-lg btn-primary btn-min-width rounded-pill" data-bs-dismiss="modal">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { UserModel } from "@/models/UserModel";

export default {
  data() {
    return {
      user: UserModel.current(),
      userData: {}
    }
  },
  mounted() {
    this.userData = UserModel.current().getData();
  }
}
</script>

<style lang="scss" scoped>
</style>