<template>
  <div class="default-page default-page-center text-center">
    <div class="about-updates-block text-secondary" v-if="checkingUpdates">
      <my-loader />
      <div class="about-updates-block-info">
        Проверяем наличие обновлений...
      </div>
    </div>

    <div class="about-updates-block text-danger" v-else-if="errCheckUpdates">
      <div class="about-updates-block-icon">
        <i class="bi bi-x-circle-fill"></i>
      </div>
      <div class="about-updates-block-info">
        Не удалось проверить обновление
      </div>
      <button class="btn btn-primary btn-lg btn-min-width rounded-pill" @click="checkUpdates">
        Проверить ещё раз
      </button>
    </div>

    <div class="about-updates-block text-secondary" v-else-if="downloadingUpdates">
      <my-loader />
      <div class="about-updates-block-info">
        Скачиванием новую версию приложения...
      </div>
    </div>

    <div class="about-updates-block text-danger" v-else-if="errDownloadUpdates">
      <div class="about-updates-block-icon">
        <i class="bi bi-x-circle-fill"></i>
      </div>
      <div class="about-updates-block-info">
        Не удалось скачать новую версию приложения
      </div>
      <div>
        <a v-if="hasUpdates" @click="downloadApp" class="btn btn-primary btn-lg btn-min-width rounded-pill">
          Попробовать ещё раз
        </a>
      </div>
    </div>

    <div class="about-updates-block text-success" v-else-if="noUpdates">
      <div class="about-updates-block-icon">
        <i class="bi bi-check-circle-fill"></i>
      </div>
      <div class="about-updates-block-info">
        У вас установлена последняя версия приложения
      </div>
      <button class="btn btn-primary btn-lg btn-min-width rounded-pill" @click="checkUpdates">
        Проверить ещё раз
      </button>
    </div>

    <div class="about-updates-block text-success" v-else-if="updatesDownloaded">
      <div class="about-updates-block-icon">
        <i class="bi bi-cloud-check-fill"></i>
      </div>
      <div class="about-updates-block-info">
        Версия {{ lastVersion }} скачена на телефон<br>
        Установите её в 3 шага:
      </div>
      <div class="text-start text-white">
        <span class="text-secondary">1:</span> Откройте папку "Загрузки"<br>
        <span class="text-secondary">2:</span> Кликните на <strong>kkosa_tasks_{{ lastVersion }}.apk</strong><br>
        <span class="text-secondary">3:</span> Во всплывающем окошке нажмите "Обновить"<br>
      </div>
    </div>

    <div class="about-updates-block text-success" v-else-if="hasUpdates">
      <div class="about-updates-block-icon">
        <i class="bi bi-cloud-arrow-down-fill"></i>
      </div>
      <div class="about-updates-block-info">
        Выпущена новая версия: {{ lastVersion }}<br>
        Для начала установки нажмите на кнопку "Скачать":
      </div>
      <div>
        <a @click="downloadApp" class="btn btn-primary btn-lg btn-min-width rounded-pill">
          Скачать {{ lastVersion }}
        </a>
      </div>
    </div>

    <div v-else>
      <button class="btn btn-primary btn-lg btn-min-width rounded-pill" @click="checkUpdates">
        Проверить обновление
      </button>
    </div>

    <div class="mt-4">
      <span class="text-secondary">Текущая версия:</span> {{ curVersion }}
    </div>

  </div>
</template>

<script>
import * as appConf from '@/../package.json';
import Api from '@/libraries/Api';
import semver from 'semver';
import EnvConfig from "@/env";

export default {
  data() {
    return {
      curVersion: '',
      lastVersion: '',
      noUpdates: false,
      hasUpdates: false,
      errCheckUpdates: false,
      checkingUpdates: false,
      downloadingUpdates: false,
      errDownloadUpdates: false,
      updatesDownloaded: false,
    }
  },
  created() {
    this.curVersion = appConf?.version || '';
  },
  methods: {
    async checkUpdates() {
      this.errCheckUpdates = false;
      this.noUpdates = false;
      this.hasUpdates = false;
      this.checkingUpdates = true;

      try {
        this.lastVersion = await Api.get('get_last_app_version');
        semver.gt(this.lastVersion, this.curVersion)
            ? (this.hasUpdates = true)
            : (this.noUpdates = true);
      } catch (e) {
        this.errCheckUpdates = true;
      }
      this.checkingUpdates = false;
    },

    downloadApp() {
      try {
        alert('call1');
        const apkFileName = `kkosa_tasks_${this.lastVersion}.apk`;
        const apkUrl = EnvConfig.SITE_ROOT_URL + 'get_app?secretCode=Sid1o8dXYDG17dhasigAGFOdvuaid';
        alert('try get = ' + apkUrl);
        // eslint-disable-next-line
        const fileTransfer = new FileTransfer();

        this.updatesDownloaded = false;
        this.errDownloadUpdates = false;
        this.downloadingUpdates = true;

        fileTransfer.download(
            encodeURI(apkUrl),
            // eslint-disable-next-line
            `${cordova.file.externalRootDirectory}download/${apkFileName}`,
            entry => {
              alert('success');
              this.updatesDownloaded = true;
              this.downloadingUpdates = false;
              console.log('download complete: ' + entry.toURL());
            },
            error => {
              alert('failed');
              this.errDownloadUpdates = true;
              this.downloadingUpdates = false;
              console.log('download error source ' + error.source);
              console.log('download error target ' + error.target);
              console.log('download error code ' + error.code);
            }
        );
        alert('done');
      } catch (e) {
        alert('error');
        alert(e);
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.about-updates-block {
  padding-left: 15px;
  padding-right: 15px;

  &-icon {
    font-size: 60px;
    line-height: 1;
  }

  &-info {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}
</style>