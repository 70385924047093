<template>
  <div class="default-page">
    <h1>Статистика работы</h1>
    <div class="text-center" style="padding: 0;height: calc(100vh - 135px);display: flex;flex-direction: column;justify-content: center;align-items: center;">
      <div style="margin-bottom: 5px;"><i class="bi bi-slash-circle-fill" style="font-size: 45px; color: #333;"></i></div>
      <span>У вас пока что нет законченных смен</span>
    </div>
  </div>
</template>

<script>
import { formatDateTime, getNow } from "@/helpers/format_date";

export default {
  name: 'StatsPage',
  data: () => {
    return {
      now: formatDateTime(getNow())
    }
  }
}
</script>

<style lang="scss" scoped>
</style>