import { createRouter, createWebHistory } from 'vue-router'
import DashboardPage from "@/components/DashboardPage";
import ArchivePage from "@/components/ArchivePage";
import StatsPage from "@/components/StatsPage";
import LoginPage from "@/components/LoginPage";
import LogoutPage from "@/components/LogoutPage";
import JokesPage from "@/components/JokesPage";
import ContactsPage from "@/components/ContactsPage";
import CheckUpdatesPage from "@/components/CheckUpdatesPage";
import SettingsPage from "@/components/SettingsPage";
import ChatsPage from "@/components/ChatsPage";
import RestPage from "@/components/RestPage";
import store from "@/store";

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/',
        name: 'dashboard',
        component: DashboardPage
    },
    {
        path: '/chats',
        name: 'chats',
        component: ChatsPage
    },
    {
        path: '/stats',
        name: 'stats',
        component: StatsPage
    },
    {
        path: '/archive',
        name: 'archive',
        component: ArchivePage
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsPage
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPage
    },
    {
        path: '/rest',
        name: 'rest',
        component: RestPage
    },
    {
        path: '/jokes',
        name: 'jokes',
        component: JokesPage
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsPage
    },
    {
        path: '/check-updates',
        name: 'check-updates',
        component: CheckUpdatesPage
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'cordova-home-page',
        component: DashboardPage
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login', '/rest', '/contacts', '/check-updates'];
    const authRequired = !publicPages.includes(to.path);
    const isUserAuth = store.state.IS_USER_AUTH;

    if (authRequired && !isUserAuth) {
        next('/login');
        return;
    }
    next();
});

export default router;