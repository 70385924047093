<template>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Загрузка...</span>
  </div>
  <div class="text-center" v-html="label" v-if="label.length"></div>
</template>

<script>
export default {
  name: 'my-loader',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>