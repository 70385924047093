import { Model } from "@/models/Model";
export class ModelLocalStorageDB extends Model {
    getTableData() {
        return JSON.parse(String(localStorage.getItem(`DB_${this.table}`)));
    }
    setTableData(data) {
        localStorage.setItem(`DB_${this.table}`, JSON.stringify(data));
    }
    getDataFromDB(id) {
        return this.getTableData().find(v => +v.id === id);
    }
    setDataToDB(id, data) {
        const all = this.getTableData();
        const foundID = all.findIndex(v => +v.id === id);
        ~foundID
            ? all[foundID] = data
            : all.push(data);
        this.setTableData(all);
        return id;
    }
    flush() {
        return +this.setDataToDB(+this.id, this.getData());
    }
}
