<template>
  <div class="default-page">
    <h1>Пока вы отдыхаете</h1>
    <div class="text-center" style="padding: 15px 0 0 0;min-height: calc(100vh - 135px);display: flex;flex-direction: column;justify-content: center;align-items: center;">
      <div class="mb-3" style="min-width: 100%">
        <div style="background: #333 url('https://t3.ftcdn.net/jpg/05/71/65/42/240_F_571654288_W1tktzV1RjxUBdz4uWavaElmur7gi6Wd.jpg') center center no-repeat; background-size: 100% auto; border-radius: 10px; width: 100%; min-height: 200px;"></div>
        <div class="lead mt-2 ">Анекдоты</div>
      </div>
      <div class="mb-3" style="min-width: 100%">
        <div class="bg-1" style="background-size: 100% auto; border-radius: 10px; width: 100%; min-height: 200px;"></div>
        <div class="lead mt-2 mb-3">Игра: Стопка</div>
      </div>
      <div class="mb-3" style="min-width: 100%">
        <div style="background: #333 url('https://www.codingnepalweb.com/wp-content/uploads/2022/01/Build-A-Memory-Card-Game-in-HTML-CSS-JavaScript.jpg') -126px center no-repeat; border-radius: 10px; width: 100%; min-height: 200px;"></div>
        <div class="lead mt-2 mb-3">Игра: 3 в ряд</div>
      </div>
      <div class="mb-3" style="min-width: 100%">
        <div style="background: #333 url('https://www.codingnepalweb.com/wp-content/uploads/2023/02/Create-A-Snake-Game-in-HTML-CSS-JavaScript-JavaScript-Game-Tutorial.jpg') -721px -246px no-repeat; border-radius: 10px; width: 100%; min-height: 200px;"></div>
        <div class="lead mt-2 mb-3">Игра: змейка</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .bg-1 {
    background: #333 url('@/assets/images/jokes_bg.jpg') center -70px no-repeat;
  }
</style>