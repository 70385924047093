import { addMagicMethods } from "@/helpers/addMagicMethods";
import { ModelLocalStorageDB } from "@/models/ModelLocalStorageDB";
import Api from "@/libraries/Api";
import store from "@/store";
export class UserModel extends ModelLocalStorageDB {
    constructor(id = null) {
        super('users', +id);
        return addMagicMethods(this);
    }
    __get(target, prop, receiver) {
        switch (prop) {
            case 'full_name':
                return [receiver.first_name, receiver.last_name].filter(v => !!v).join(' ');
            case 'avatar_full_url':
                return `/upl/users/${receiver.avatar}`;
        }
        return super.__get(target, prop, receiver);
    }
    static current() {
        const user = new UserModel();
        const data = JSON.parse(localStorage.getItem('g_user_data') || '{}');
        user.setData(data);
        return user;
    }
    async tryLogin(login, password) {
        let isAuth = false;
        try {
            const response = await Api.post('login', { login, password });
            isAuth = !!(response === null || response === void 0 ? void 0 : response.token);
            localStorage.setItem('g_user_data', JSON.stringify(response));
            store.commit('setUserAuth', isAuth);
            return isAuth;
        }
        catch (e) {
            return false;
        }
    }
    isAuth() {
        var _a;
        return !!((_a = UserModel.current()) === null || _a === void 0 ? void 0 : _a.id);
    }
    logout() {
        localStorage.removeItem('g_user_data');
        store.commit('setUserAuth', false);
    }
}
